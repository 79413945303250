import { ReactComponent as TopImage } from '~/assets/top.svg';
import './App.css';

function App() {
  return (
    <div className="">
      <header className="fixed top-0 w-full bg-white px-4">
        <h1 className="flex h-16 items-center bg-white text-lg font-bold text-neutral-900">
          <img src="/logo.png" alt="会社ロゴ" className="mr-3 h-8 w-8" />
          株式会社スリーライナ
        </h1>
      </header>
      <div className="flex min-h-screen w-full flex-col items-center justify-center gap-8 bg-white px-4 md:flex-row">
        <TopImage className="max-h-[60%] max-w-full" />
        <h2 className="whitespace-nowrap text-2xl font-bold leading-loose underline decoration-primary decoration-8 underline-offset-4 md:text-3xl md:leading-loose">
          世界の機能を実装する
          <br />
          エンジニアリングの会社
        </h2>
      </div>
      <div className="w-full bg-neutral-100 py-16">
        <div className="text-center">
          <h2 className="text-lg font-bold">サービス紹介</h2>
          <span className="text-xs text-neutral-400">Service</span>
        </div>
        <div className="mx-auto mt-16 grid max-w-5xl grid-cols-1 flex-col justify-center gap-8 px-4 md:grid-cols-2 md:flex-row">
          <div className="mb-4 flex flex-col gap-8 md:flex-row">
            <div className="mb-4 flex aspect-square w-full shrink-0 items-center justify-center bg-white text-4xl font-bold shadow-lg md:w-48 md:text-xl">
              ENNY
            </div>
            <div>
              <h3 className="text-lg font-bold underline decoration-primary decoration-4 underline-offset-4">
                ENNY(エニー)
              </h3>
              <p className="mt-3 text-sm">
                お金の専門家によるライフプランニングの作成サービス。お金に関する相談や授業を受けることができます。
              </p>
            </div>
          </div>
          <div className="mb-4 flex flex-col gap-8 md:flex-row">
            <div className="mb-4 flex aspect-square w-full shrink-0 items-center justify-center bg-white text-4xl font-bold shadow-lg md:w-48 md:text-xl">
              Kajinote
            </div>
            <div>
              <h3 className="text-lg font-bold underline decoration-primary decoration-4 underline-offset-4">
                Kajinote(家事ノート)
              </h3>
              <p className="mt-3 text-sm">
                普段の家事のログをアプリに記録し夫婦で共有できます。どのくらい家事分担ができているかを把握することできます。
              </p>
            </div>
          </div>
          <div className="mb-4 flex flex-col gap-8 md:flex-row">
            <div className="mb-4 flex aspect-square w-full shrink-0 items-center justify-center bg-white text-4xl font-bold shadow-lg md:w-48 md:text-xl">
              SYSTEM
              <br />
              DEVELOPMENT
            </div>
            <div>
              <h3 className="text-lg font-bold underline decoration-primary decoration-4 underline-offset-4">
                システム開発
              </h3>
              <p className="mt-3 text-sm">
                アプリやウェブサービスの開発サービス。ウェブとアプリの両方に対応したサービスを開発します。
              </p>
            </div>
          </div>
        </div>
      </div>

      <div className="w-full bg-white py-16">
        <div className="text-center">
          <h2 className="text-lg font-bold">会社概要</h2>
          <span className="text-xs text-neutral-400">Company</span>
        </div>
        <div className="mx-auto mt-16 flex max-w-5xl flex-col justify-center gap-8 px-4 md:flex-row">
          <dl className="grid grid-cols-3 gap-8">
            <dt>会社名</dt>
            <dd className="col-span-2 border-l-4 border-neutral-300 pl-4">株式会社スリーライナ</dd>
            <dt>設立</dt>
            <dd className="col-span-2 border-l-4 border-neutral-300 pl-4">2018年8月</dd>
            <dt>所在地</dt>
            <dd className="col-span-2 border-l-4 border-neutral-300 pl-4">東京都渋谷区渋谷3-26-16 第五叶ビル5F</dd>
            <dt>代表</dt>
            <dd className="col-span-2 border-l-4 border-neutral-300 pl-4">山本　航</dd>
          </dl>
        </div>
      </div>

      <div className="bg-neutral-100 py-4 text-center text-xs font-bold">2023 &copy; Threeliner Inc.</div>
    </div>
  );
}

export default App;
